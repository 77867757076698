/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import {
  stripslashes,
  getCalculatedAmount,
  showLoader,
  hideLoader,
  getOrderDateTime,
  getPromoCkValue,
  removePromoCkValue,
  resetCrtStyle,
  showAlert,
} from "../Helpers/SettingHelper";
import {
  deliveryId,
  dineinId,
  cateringId,
  CountryTxt,
  pickupId,
  noimage,
  currencySybmol,
  mapcountry,
  appId,
  apiUrl,
  language,
} from "../Helpers/Config";
import shoppingBag from "../../common/images/shopping-bag.svg";
import shoppingBagDark from "../../common/images/shopping-bag-dark.svg";
import mofifyimage from "../../common/images/modify-white.png";
import mofifydeleteimage from "../../common/images/delete-white.png";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";

import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_OUTLET_SUBCHARGE,
} from "../../actions";
import { setMinutes, setHours, format } from "date-fns";
var Parser = require("html-react-parser");

class CartSideBar extends Component {
  constructor(props) {
    super(props);
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");
    var defaultAvilablityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderOutletId =
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== undefined &&
      typeof cookie.load("orderOutletId") !== "undefined"
        ? cookie.load("orderOutletId")
        : "";
    var orderTAT =
      cookie.load("orderTAT") !== "" &&
      typeof cookie.load("orderTAT") !== undefined &&
      typeof cookie.load("orderTAT") !== "undefined"
        ? cookie.load("orderTAT")
        : "";

    var servicesurchargeArr = Array();
    servicesurchargeArr["servicechrg_displaylabel"] = "Service Charge";
    servicesurchargeArr["servicechrg_type"] = "percentage";
    servicesurchargeArr["servicechrg_per"] = 0;
    servicesurchargeArr["servicechrg_amount"] = 0;
    servicesurchargeArr["surcharge_amount"] = 0;
    servicesurchargeArr["surcharge_type"] = "";
    servicesurchargeArr["surcharge_date"] = "";
    servicesurchargeArr["surcharge_frmtime"] = "";
    servicesurchargeArr["surcharge_totime"] = "";

    this.state = {
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartItemsLength: 0,
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      cateringCartItems: [],
      cateringCartDetails: [],
      cateringCartTotalItmCount: 0,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
      updateCartResponse: [],
      startMsg: 0,
      defaultAvilablityId: defaultAvilablityId,
      seletedAvilablityId: defaultAvilablityId,
      seletedOutletId: orderOutletId,
      order_tat_time: orderTAT,
      getDateTimeFlg: "yes",
      initialSlot: true,
      servicesurchargeval: servicesurchargeArr,
    };
  }

  componentWillMount() {
    var avltyTxt = cookie.load("defaultAvilablityId");
    this.props.getCartDetail();
    var availabilityIdTxt = cookie.load("defaultAvilablityId");

    if (dineinId === availabilityIdTxt) {
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime("", orderTAT);
      var formatedDate = format(orderDateTmTxt, "yyyy-MM-dd");
      var OrderHours = orderDateTmTxt.getHours();
      var OrderMunts = orderDateTmTxt.getMinutes();
      var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = formatedDate;
      OrdDateTimeArr["OrdTime"] = orderTime;
      this.getServiceChargeAmt(OrdDateTimeArr);
    }
  }

  getServiceChargeAmt(OrdDateTimeArr) {
    var availabilityIdTxt = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    var orderDate = "";
    var orderTime = "";
    if (availabilityIdTxt === deliveryId || availabilityIdTxt === dineinId) {
      orderDate = OrdDateTimeArr["OrdDate"];
      orderTime = OrdDateTimeArr["OrdTime"];
    }

    var servicesurchargeArr = this.state.servicesurchargeval;
    var online_servicecharge_type = servicesurchargeArr["servicechrg_type"];
    var onlineservice_displaylabel =
      servicesurchargeArr["servicechrg_displaylabel"];
    var online_servicecharge_per = 0,
      online_servicecharge_amount = 0,
      subchr_amount = 0;
    var subchr_type = "",
      subchr_date = "",
      subchr_frmtime = "",
      subchr_totime = "";

    var urlPrms =
      "&availabilityid=" +
      availabilityIdTxt +
      "&outletid=" +
      orderOutletId +
      "&orderdate=" +
      orderDate +
      "&ordertime=" +
      orderTime +
      "&";
    this.props.updateCartDetail();
    var urlShringTxt =
      apiUrl + "outlets/outletsubchargenew?app_id=" + appId + urlPrms;
    axios.get(urlShringTxt).then((res) => {
      if (res.data.status === "ok") {
        online_servicecharge_per =
          res.data.online_service_charge !== ""
            ? parseFloat(res.data.online_service_charge)
            : 0;
        online_servicecharge_amount = 0;

        if (
          (res.data.subcharge_apply === "Yes" &&
            availabilityIdTxt === deliveryId) ||
          availabilityIdTxt === dineinId
        ) {
          var subchr_data = res.data.result_set[0];
          if (res.data.subcharge_apply === "Yes") {
            subchr_amount =
              subchr_data.subchr_value !== ""
                ? parseFloat(subchr_data.subchr_value)
                : 0;
            subchr_type = subchr_data.subchr_type;
            if (subchr_data.subchr_type === "Time") {
              subchr_frmtime = this.tmConv24(subchr_data.subchr_from_time);
              subchr_totime = this.tmConv24(subchr_data.subchr_to_time);
            } else {
              subchr_date = subchr_data.subchr_date;
            }
          }
        }
      }

      servicesurchargeArr["servicechrg_displaylabel"] =
        res.data.onlineservice_displaylbl;
      servicesurchargeArr["servicechrg_type"] = res.data.onlineservice_type;
      servicesurchargeArr["servicechrg_per"] = res.data.online_service_charge;
      servicesurchargeArr["surcharge_amount"] = subchr_amount;
      servicesurchargeArr["surcharge_type"] = subchr_type;
      servicesurchargeArr["surcharge_date"] = subchr_date;
      servicesurchargeArr["surcharge_frmtime"] = subchr_frmtime;
      servicesurchargeArr["surcharge_totime"] = subchr_totime;

      this.setState({ servicesurchargeval: servicesurchargeArr });
    });
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      if (
        this.props.match.path !== "/myaccount" &&
        this.props.match.path !== "/myorders" &&
        this.props.match.path !== "/rewards" &&
        this.props.match.path !== "/myreservations" &&
        this.props.match.path !== "/referral" &&
        this.props.match.path !== "/mypromotions" &&
        this.props.match.path !== "/myvouchers" &&
        this.props.match.path !== "/thankyou/:orderId" &&
        this.props.match.path !== "/reservation" &&
        this.props.match.path !== "/reservation-summary" &&
        this.props.match.path !== "/reservation-thankyou" &&
        this.props.match.path !== "/reservation_suggest/:acceptreject/:resid"
      ) {
        headerProps.prpSateValChange("cartflg", "no");
      }
      this.props.getCartDetail();
      resetCrtStyle();
    }

    if (this.state.cartItems !== headerProps.cartItems) {
      if (this.state.cartItemsLength !== headerProps.cartItems.length) {
        this.setState(
          {
            cartItems: headerProps.cartItems,
            cartItemsLength: headerProps.cartItems.length,
          },
          function () {
            if (
              this.props.match.path !== "/myaccount" &&
              this.props.match.path !== "/myorders" &&
              this.props.match.path !== "/rewards" &&
              this.props.match.path !== "/myreservations" &&
              this.props.match.path !== "/referral" &&
              this.props.match.path !== "/mypromotions" &&
              this.props.match.path !== "/myvouchers" &&
              this.props.match.path !== "/thankyou/:orderId" &&
              this.props.match.path !== "/reservation" &&
              this.props.match.path !== "/reservation-summary" &&
              this.props.match.path !== "/reservation-thankyou" &&
              this.props.match.path !==
                "/reservation_suggest/:acceptreject/:resid"
            ) {
              headerProps.prpSateValChange("cartList", headerProps.cartItems);
              setTimeout(function () {
                headerProps.prpSateValChange(
                  "cartItemsLength",
                  headerProps.cartItems.length
                );
              }, 50);
            }
          }
        );
      }
    }

    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");

    if (this.state.updateCartResponse !== headerProps.updateCartResponse) {
      if (Object.keys(headerProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: headerProps.updateCartResponse },
          function () {
            var Response = headerProps.updateCartResponse;

            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }

    if (this.state.cartDetails !== headerProps.cartDetails) {
      if (Object.keys(headerProps.cartDetails).length > 0) {
        this.setState({ cartDetails: headerProps.cartDetails });
        if (this.state.initialSlot === true) {
          this.setState({ getDateTimeFlg: "yes", initialSlot: false });
          headerProps.setdateTimeFlg("tmflg", "yes");

          var orderTAT =
            typeof cookie.load("orderTAT") === "undefined"
              ? ""
              : cookie.load("orderTAT");
          var orderDateTmTxt = getOrderDateTime("", orderTAT);
          var formatedDate = format(orderDateTmTxt, "yyyy-MM-dd");
          var OrderHours = orderDateTmTxt.getHours();
          var OrderMunts = orderDateTmTxt.getMinutes();
          var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);
        }
      }
    }

    if (this.state.outletsubcharge !== headerProps.outletsubcharge) {
      if (Object.keys(headerProps.outletsubcharge).length > 0) {
        var availabilityIdTxt = cookie.load("defaultAvilablityId");
        var subchr_amount = 0;
        var subchr_type = "",
          subchr_date = "",
          subchr_frmtime = "",
          subchr_totime = "";

        var online_servicecharge_per =
          headerProps.outletsubcharge[0].online_service_charge !== ""
            ? parseFloat(headerProps.outletsubcharge[0].online_service_charge)
            : 0;
        var online_servicecharge_amount = 0;

        if (
          (headerProps.outletsubcharge[0].subcharge_apply === "Yes" &&
            availabilityIdTxt === deliveryId) ||
          availabilityIdTxt === dineinId
        ) {
          var subchr_data = headerProps.outletsubcharge[0];
          if (headerProps.outletsubcharge[0].subcharge_apply === "Yes") {
            subchr_amount =
              subchr_data.subchr_value !== ""
                ? parseFloat(subchr_data.subchr_value)
                : 0;
            subchr_type = subchr_data.subchr_type;
            if (subchr_data.subchr_type === "Time") {
              subchr_frmtime = this.tmConv24(subchr_data.subchr_from_time);
              subchr_totime = this.tmConv24(subchr_data.subchr_to_time);
            } else {
              subchr_date = subchr_data.subchr_date;
            }
          }
        }

        var servicesurchargeArr = this.state.servicesurchargeval;
        servicesurchargeArr["servicechrg_displaylabel"] =
          headerProps.outletsubcharge[0].onlineservice_displaylbl;
        servicesurchargeArr["servicechrg_type"] =
          headerProps.outletsubcharge[0].onlineservice_type;
        servicesurchargeArr["servicechrg_per"] =
          headerProps.outletsubcharge[0].online_service_charge;
        servicesurchargeArr["surcharge_amount"] = subchr_amount;
        servicesurchargeArr["surcharge_type"] = subchr_type;
        servicesurchargeArr["surcharge_date"] = subchr_date;
        servicesurchargeArr["surcharge_frmtime"] = subchr_frmtime;
        servicesurchargeArr["surcharge_totime"] = subchr_totime;

        this.setState({ servicesurchargeval: servicesurchargeArr });
      }
    }
  }

  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleChange = (event) => {};
  removePromoFun(event) {
    event.preventDefault();
    removePromoCkValue();
    this.props.getCartDetail();
  }

  closeCartlist() {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");
  }

  changeAddrrFun(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#warning-popup",
      },
      type: "inline",
    });
    return false;
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, { path: "/" });
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, { path: "/" });
      this.setState({ unitnumber2: event.target.value });
    }
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, { path: "/" });
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, { path: "/" });
      this.setState({ unitnumber2: event.target.value });
    }
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState(
        {
          seleted_ord_time: tmSltArr["startTime"],
          seleted_ord_slot: ordTime,
          seleted_ord_slotTxt: ordTime,
          seleted_ord_slot_str: ordTime,
          seleted_ord_slot_end: ordTime,
        },
        function () {
          this.setOrderOutletDateTimeDataNew();
        }.bind(this)
      );
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState(
        {
          seleted_ord_time: tmSltArr["startTime"],
          seleted_ord_slot: tmSltArr["ordSlotVal"],
          seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
          seleted_ord_slot_str: tmSltArr["ordSlotStr"],
          seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
        },
        function () {
          this.setOrderOutletDateTimeDataNew();
        }.bind(this)
      );
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeDataNew() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);

      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPadNew(OrderHours) +
        ":" +
        this.convPadNew(OrderMunts) +
        ":" +
        this.convPadNew(OrderSecnd);
      cookie.save("orderDateTime", orderDateTime, { path: "/" });
      cookie.save("deliveryDate", deliveryDate, { path: "/" });
      cookie.save("deliveryTime", deliveryTime, { path: "/" });
      this.props.getCartDetail();

      console.log(deliveryDate, "deliveryDate");
      console.log(deliveryTime, "deliveryTime");
      if (deliveryDate !== "" && deliveryTime !== "") {
        var order_delivery_date = format(seletedOrdDate, "dd-MM-yyyy");
        this.props.getOutletSurchargeDetail(order_delivery_date, deliveryTime);
      }
    }
  }

  convPadNew(d) {
    var rstVl = d < 10 ? "0" + parseInt(d) : d.toString();
    return rstVl.toString();
  }

  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();
      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var zoneDetails = this.props.zonedetails;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        serviceSubChrArr
      );

      var proStockAndDatetimeAvl = "yes";
      var itmsArrrr = cartItemsArr.map((cartItms, itmsIndex) => {
        if (
          cartItms.pro_current_datetime_avl === "no" ||
          cartItms.pro_fullfill_datetime_avl === "no" ||
          cartItms.pro_current_stock_avl === "no" ||
          cartItms.pro_fullfill_stock_avl === "no"
        ) {
          proStockAndDatetimeAvl = "no";
        }
        return "";
      });

      var orderDateTime =
        typeof cookie.load("orderDateTime") === "undefined"
          ? ""
          : cookie.load("orderDateTime");
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      if (cookie.load("defaultAvilablityId") === dineinId) {
        var orderDateTmTxt = getOrderDateTime("", orderTAT);
        var OrderDate = format(orderDateTmTxt, "yyyy-MM-dd");
        /* For Advanced Slot */
        var OrderHours = orderDateTmTxt.getHours();
        var OrderMunts = orderDateTmTxt.getMinutes();
        var OrderSecnd = orderDateTmTxt.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);
        var deliveryDate = format(orderDateTmTxt, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);

        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });
      } else {
        var orderDateTmTxt = getOrderDateTime(orderDateTime, orderTAT);
      }

      var availabilityTxt = language.pickup;

      if (cookie.load("defaultAvilablityId") === deliveryId) {
        availabilityTxt = language.delivery;
      } else if (cookie.load("defaultAvilablityId") === dineinId) {
        availabilityTxt = language.dinein;
      }
      return (
        <div className="hcart_dropdown">
          <div className="hcart_tt">
            <div
              id="cart-close-span"
              onClick={this.closeCartlist.bind(this)}
            ></div>
            <h3 className="text-uppercase">
              {appId == "E338A8BC-4B4A-427A-A683-60A2F1E173D2"
                ? language.whatyouenjoy
                : language.yourorderdetails}
            </h3>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_table">
              <div className="cart_body">
                {cookie.load("defaultAvilablityId") === deliveryId && (
                  <div className="delivery-cart-div">
                    <div className="col-sm-cls text-left">
                      <h4>{language.orderhandled}</h4>
                      <p>{cookie.load("orderOutletName")}</p>
                      <p>{cookie.load("orderHandledByText")}</p>
                    </div>

                    <div className="col-sm-cls text-right">
                      <h4>{language.deliveryloc}</h4>
                      <p>{cookie.load("orderDeliveryAddress")}</p>
                      {mapcountry === "sg" && (
                        <p>
                          {CountryTxt} {cookie.load("orderPostalCode")}
                        </p>
                      )}
                      <div className="hcart_add_innr_loca">
                        <p>#</p>
                        <div className="input_field">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.unitnumber1}
                            name="unit_no1"
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.unitnumber2}
                            name="unit_no2"
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                      </div>
                      <p>
                        <span>
                          <a
                            className="cart-loc-change"
                            href="/"
                            onClick={this.changeAddrrFun.bind(this)}
                          >
                            {language.changedeliveryloc}
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                )}

                {cookie.load("defaultAvilablityId") === pickupId && (
                  <div className="pickup-cart-div">
                    <div className="cart_row cart-header-first">
                      <div className="row-replace">
                        <div className="col-xs-12 cart_left text-center">
                          <h4>{language.pickuploc}</h4>
                          <p>{cookie.load("orderOutletName")}</p>
                          <p>{cookie.load("orderHandledByText")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {cookie.load("defaultAvilablityId") === dineinId && (
                  <div className="pickup-cart-div">
                    <div className="cart_row cart-header-first">
                      <div className="row-replace">
                        <div className="col-xs-12 cart_left text-center">
                          <h4>
                            {appId === "D93083AF-FD2F-4037-AD20-44BD976DC5E0"
                              ? language.instoreordering
                              : language.dinein}{" "}
                            {location}
                          </h4>
                          <p>{cookie.load("orderOutletName")}</p>
                          <p>{cookie.load("orderHandledByText")}</p>
                          <h4>
                            {language.table}: {cookie.load("orderTableNo")}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="cart_row cart-header-second">
                  <div className="col-sm-cls text-left">
                    {cookie.load("defaultAvilablityId") === deliveryId && (
                      <OrderAdvancedDatetimeSlot
                        {...this.props}
                        hdrState={this.state}
                        setdateTimeFlg={this.setdateTimeFlg}
                        labelDateName={language.deliverydate}
                        labelTimeName={language.deliverytime}
                      />
                    )}
                    {cookie.load("defaultAvilablityId") === pickupId && (
                      <OrderAdvancedDatetimeSlot
                        {...this.props}
                        hdrState={this.state}
                        setdateTimeFlg={this.setdateTimeFlg}
                        labelDateName={language.pickupdate}
                        labelTimeName={language.pickuptime}
                      />
                    )}
                  </div>
                </div>

                <div className="product_orders_top">
                  <h4>{language.youritems}</h4>
                  <div className="product_modify_cart">
                    <a
                      href="/"
                      onClick={this.clearCartItm.bind(this)}
                      className="hclear_cart"
                      title={language.clearcart}
                    >
                      {language.clearcart}
                    </a>
                  </div>
                </div>

                <div className="mobile-cart">
                  <h4>{language.youritems}</h4>
                  <div className="product_modify_cart">
                    <a
                      href="/menu"
                      className="hclear_cart modify_item"
                      title={language.additems}
                    >
                      {" "}
                      <img src={mofifyimage} alt={language.additems} />{" "}
                      {language.additems}{" "}
                    </a>
                    <a
                      href="/"
                      onClick={this.clearCartItm.bind(this)}
                      className="hclear_cart"
                      title={language.clearcart}
                    >
                      <img src={mofifydeleteimage} alt={language.clearcart} />
                    </a>
                  </div>
                </div>

                {this.cartItemList()}
              </div>

              <div className="cart_footer">
                <div className="cart_row">
                  <p className="text-uppercase">{language.subtotal}</p>
                  <span>
                    {currencySybmol}
                    {calculatedAmount["cartSubTotalAmount"]}
                  </span>
                </div>
                {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">{language.delivery}</p>
                    <span>
                      {currencySybmol}
                      {parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
                        2
                      )}
                    </span>
                  </div>
                )}

                {parseFloat(calculatedAmount["serviceCharge"]) > 0 && (
                  <div className="cart_row">
                    {calculatedAmount["servicechrgType"] === "fixed" ? (
                      <p className="text-uppercase">
                        {calculatedAmount["servicechrgDisplaylbl"] !== ""
                          ? calculatedAmount["servicechrgDisplaylbl"]
                          : language.servicecharge}
                      </p>
                    ) : (
                      <p className="text-uppercase">
                        {calculatedAmount["servicechrgDisplaylbl"] !== ""
                          ? calculatedAmount["servicechrgDisplaylbl"]
                          : language.servicecharge}{" "}
                        ({calculatedAmount["servicechargePer"]}%)
                      </p>
                    )}
                    <span>
                      {currencySybmol}
                      {parseFloat(calculatedAmount["serviceCharge"]).toFixed(2)}
                    </span>
                  </div>
                )}

                {parseFloat(calculatedAmount["surCharge"]) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">{language.surcharge}</p>
                    <span>
                      {currencySybmol}
                      {parseFloat(calculatedAmount["surCharge"]).toFixed(2)}
                    </span>
                  </div>
                )}

                {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">
                      {language.additionaldelivery}
                    </p>
                    <span>
                      {currencySybmol}
                      {parseFloat(
                        calculatedAmount["additionalDelivery"]
                      ).toFixed(2)}
                    </span>
                  </div>
                )}
                {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                  <div className="cart_row  promo-cart-row">
                    <p className="text-uppercase">{language.promocode}</p>
                    <span>
                      {currencySybmol}
                      {parseFloat(calculatedAmount["promotionAmount"]).toFixed(
                        2
                      )}
                    </span>
                    <a
                      href="/"
                      onClick={this.removePromoFun.bind(this)}
                      className="cart_remove"
                    ></a>
                  </div>
                )}
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">
                      {language.gst} ({calculatedAmount["orderDisplayGst"]} %)
                    </p>
                    <span>
                      {currencySybmol}
                      {parseFloat(calculatedAmount["orderGstAmount"]).toFixed(
                        2
                      )}
                    </span>
                  </div>
                )}

                {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">
                      {language.voucherdisocuntamt}
                    </p>
                    <span>
                      {currencySybmol}
                      {parseFloat(
                        calculatedAmount["voucherDiscountAmount"]
                      ).toFixed(2)}
                    </span>
                  </div>
                )}

                <div className="cart_row grant-total-cls">
                  <p className="text-uppercase">{language.total}</p>
                  <span>
                    <sup>{currencySybmol}</sup>
                    {calculatedAmount["grandTotalAmount"]}
                  </span>
                </div>

                {calculatedAmount["orderDisplayGstInclusive"] > 0 &&
                  calculatedAmount["orderGstInclusiveAmount"] > 0 && (
                    <p className="gst-inclusive-lbl">
                      {calculatedAmount["orderGstInclusiveAmountText"]}
                    </p>
                  )}

                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div className="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type}{" "}
                    {currencySybmol}
                    {cartDetailsArr.cart_special_discount} {language.applied}
                  </div>
                )}
              </div>
              {cookie.load("defaultAvilablityId") === deliveryId &&
                this.loadMinOrderPercentage()}

              {cookie.load("defaultAvilablityId") === deliveryId &&
                this.loadDeliveryPercentage()}
            </div>
          </div>
          {this.props.pageName === "header" &&
            proStockAndDatetimeAvl === "yes" && (
              <div
                className="cartaction_bottom"
                style={{ top: "100dvh", transform: "translateY(-34px)" }}
              >
                <div className="cartaction_inbtn">
                  <Link
                    to={"/checkout"}
                    className="button btn_pink cartaction_checkout"
                    title={language.proceedcheckout}
                    onClick={this.proceedToCheckout.bind(this)}
                  >
                    {language.proceedcheckout}
                  </Link>
                </div>
              </div>
            )}
        </div>
      );
    } else {
      if ($(".hcart_dropdown.open").length > 0) {
        this.closeCartlist();
      }
      return (
        <div
          className={
            this.props.pageName === "products"
              ? "hcart_dropdown open"
              : "hcart_dropdown"
          }
        >
          <div className="hcart_tt">
            <div
              id="cart-close-span"
              onClick={this.closeCartlist.bind(this)}
            ></div>
            <h3 className="text-uppercase">{language.yourorderdetails}</h3>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_body">
              {cookie.load("defaultAvilablityId") === deliveryId ? (
                <div className="del_address">
                  <h5>{language.deliveryaddress}</h5>
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="text"
                        id="postal_code"
                        placeholder={language.postalcode}
                        className="form-control"
                        readOnly={true}
                        defaultValue={cookie.load("orderPostalCode")}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input_field">
                      <input
                        type="text"
                        placeholder={language.addressline1}
                        className="form-control"
                        readOnly={true}
                        defaultValue={cookie.load("orderDeliveryAddress")}
                      />
                    </div>
                  </div>
                  <div className="form-group half-fg">
                    <div className="input_field">
                      <input
                        type="text"
                        placeholder={language.unitno1}
                        className="form-control"
                        name="unit_no1"
                        onChange={this.handleAddrChange.bind(this)}
                        value={this.state.unitnumber1}
                      />
                    </div>
                    <div className="input_field">
                      <input
                        type="text"
                        placeholder={language.unitno2}
                        className="form-control"
                        name="unit_no2"
                        onChange={this.handleAddrChange.bind(this)}
                        value={this.state.unitnumber2}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pickup-cart-div">
                  <div className="cart_row cart-header-first">
                    <div className="row-replace">
                      <div className="col-xs-12 cart_left text-center">
                        <h4>{language.pickuploc}</h4>
                        <p>{cookie.load("orderOutletName")}</p>
                        <p>{cookie.load("orderHandledByText")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {this.props.pageName === "header" &&
              proStockAndDatetimeAvl === "yes" && (
                <div
                  className="cartaction_bottom"
                  style={{ top: "100dvh", transform: "translateY(-34px)" }}
                >
                  <Link
                    to={"/checkout"}
                    className="button btn_pink cartaction_checkout"
                    onClick={this.proceedToCheckout.bind(this)}
                    title={language.proceedcheckout}
                  >
                    {language.proceedcheckout}
                  </Link>
                </div>
              )}
          </div>
        </div>
      );
    }
  }

  proceedToCheckout(event) {
    event.preventDefault();
    if (
      cookie.load("userAccountType") === "2" &&
      cookie.load("defaultAvilablityId") === dineinId
    ) {
      cookie.save("continuetochekout", "Yes", { path: "/" });
      this.props.history.push("/checkout");
    } else {
      this.props.history.push("/checkout");
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        serviceSubChrArr
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar progress_bar_low"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {currencySybmol}
              {remainAmnt} {language.morefreedelivery}
            </p>
          </div>
        </div>
      );
    }
  }

  /* this  function used to load delivery percentage */
  loadMinOrderPercentage() {
    var minimumAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var serviceSubChrArr = this.state.servicesurchargeval;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        serviceSubChrArr
      );
      minimumAmnt = parseFloat(calculatedAmount["minimumAmnt"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / minimumAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(minimumAmnt - subTotal).toFixed(2);
    }

    if (minimumAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div progress_bar_one">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              {currencySybmol}
              {remainAmnt} {language.moreminorder}
            </p>
          </div>
        </div>
      );
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div>
          <div
            className="cart_row product-details"
            id={"rowcartid-" + product.cart_item_id}
            key={index}
          >
            <div className="col-sm-cls cart_left">
              <div className="cart_img">
                {product.cart_item_product_image !== "" ? (
                  <img
                    src={product.cart_item_product_image}
                    alt={stripslashes(product.cart_item_product_name)}
                  />
                ) : (
                  <img
                    src={noimage}
                    alt={stripslashes(product.cart_item_product_name)}
                  />
                )}
              </div>
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>

                {product.cart_item_type === "Gift" ? (
                  <div>
                    <p>
                      {" "}
                      {language.name}:
                      {product.cart_item_product_voucher_gift_name !== "" &&
                      product.cart_item_product_voucher_gift_name != null
                        ? " " + product.cart_item_product_voucher_gift_name
                        : ""}
                    </p>

                    <p>
                      {" "}
                      {language.mobileno}:
                      {product.cart_item_product_voucher_gift_mobile !== "" &&
                      product.cart_item_product_voucher_gift_mobile != null
                        ? " " + product.cart_item_product_voucher_gift_mobile
                        : ""}
                    </p>

                    <p>
                      {" "}
                      {language.email}:
                      {product.cart_item_product_voucher_gift_email !== "" &&
                      product.cart_item_product_voucher_gift_email != null
                        ? " " + product.cart_item_product_voucher_gift_email
                        : ""}
                    </p>

                    <p>
                      {" "}
                      {language.message}:{" "}
                      {product.cart_item_product_voucher_gift_message !== "" &&
                      product.cart_item_product_voucher_gift_message != null
                        ? " " + product.cart_item_product_voucher_gift_message
                        : ""}{" "}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                <h4>
                  {product.cart_item_voucher_id !== "" &&
                  product.cart_item_voucher_id != null
                    ? language.discountapplied
                    : ""}{" "}
                </h4>

                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="notes-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    {currencySybmol}
                    {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type}{" "}
                    {language.discountapplied}
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>
                  {currencySybmol}
                  {product.cart_item_total_price}
                </p>
              </div>

              {product.cart_item_voucher_product_free != 1 ? (
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.updateCartQty.bind(this, product, "decr")}
                  >
                    -
                  </span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span
                    className="qty_plus"
                    onClick={this.updateCartQty.bind(this, product, "incr")}
                  >
                    +
                  </span>
                </div>
              ) : (
                <div className="qty_bx free_product">
                  <span className="qty_minus"></span>
                  <input type="text" value={product.cart_item_qty} readOnly />
                  <span className="qty_plus"></span>
                </div>
              )}
            </div>
            <a
              href="/"
              onClick={this.deleteCartItm.bind(this, product)}
              className="cart_remove"
            ></a>
          </div>
          {this.showProAvblErrorMgs(product)}
          {this.showProStockErrorMgs(product)}
        </div>
      ));
    }
  }

  showProAvblErrorMgs(cartItms) {
    var errorMgs = "";
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "delivery/pickup";
    if (availability === deliveryId) {
      showtext = "delivery";
    } else if (availability === pickupId) {
      showtext = "pickup";
    } else if (availability === dineinId) {
      showtext = "dinein";
    }
    if (cartItms.pro_current_datetime_avl === "no") {
      errorMgs =
        "This product is not available for order today. Please refer to the product description.";
    } else if (cartItms.pro_fullfill_datetime_avl === "no") {
      errorMgs =
        "This product is not available for " +
        showtext +
        " on the selected date and time. Please refer to the product description.";
    }

    if (errorMgs !== "") {
      return <div className="pro-avbl-errormsg">{errorMgs}</div>;
    }
  }

  showProStockErrorMgs(cartItms) {
    var errorMgs = "";
    var availability = cookie.load("defaultAvilablityId");
    var showtext = "delivery/pickup";
    if (availability === deliveryId) {
      showtext = "delivery";
    } else if (availability === pickupId) {
      showtext = "pickup";
    } else if (availability === dineinId) {
      showtext = "dinein";
    }
    if (cartItms.pro_current_stock_avl === "no") {
      errorMgs = "The selected quantity is not available for order.";
    } else if (cartItms.pro_fullfill_stock_avl === "no") {
      errorMgs =
        "The selected quantity is not available for " +
        showtext +
        " on the selected date and time.";
    }

    if (errorMgs !== "") {
      return <div className="pro-avbl-errormsg">{errorMgs}</div>;
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice =
          modVlPrice > 0 ? " (+" + currencySybmol + modVlPrice + ")" : "";
        temp_html +=
          "<p>" + modName + ":</p> <p>" + modval + newModVlPrice + "</p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p>" +
            comboName +
            ": </p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p>" +
            comboName +
            ": </p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice =
            comboPrice > 0 ? " (+" + currencySybmol + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice =
          modValPrice > 0 ? " (+" + currencySybmol + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;

      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    this.setState({ startMsg: 1 });
    removePromoCkValue();
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }

  gotoCateringChkut(event) {
    event.preventDefault();

    var cartTotal = this.props.cateringCartTotalItmCount;
    if (parseFloat(cartTotal) > 0) {
      if ($("body").width() <= 980) {
        if ($(".product-rhs .hcart_dropdown.active").length > 0) {
          $(".product-rhs .hcart_dropdown").removeClass("active");
        } else {
          $(".product-rhs .hcart_dropdown").addClass("active");
        }
      } else {
        if ($("#firstcategory").length > 0) {
          this.props.history.push("/catering/" + $("#firstcategory").val());
        } else {
          cookie.save("cateringCheckoutFromCart", "Yes", { path: "/" });
          this.props.history.push("/catering-checkout");
        }
      }
    }
  }

  render() {
    var avltyTxt = cookie.load("defaultAvilablityId");
    var settingsArr = this.props.globalsettings;
    var currenturl = window.location.href;
    var client_theme = "";
    var substring1 = "checkout";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        client_theme = settingsArr[0].result_set.client_theme;
      }
    }

    var serviceSubChrArr = this.state.servicesurchargeval;
    var promoTionArr = getPromoCkValue();
    var calculatedAmount = getCalculatedAmount(
      this.props.globalsettings,
      this.props.zonedetails,
      this.props.cartDetails,
      promoTionArr,
      serviceSubChrArr
    );

    if (avltyTxt === cateringId) {
      return (
        <a
          href="/"
          onClick={this.gotoCateringChkut.bind(this)}
          className="hcartdd_trigger"
          title=""
        >
          {(() => {
            if (client_theme == 0) {
              return <img src={shoppingBag} alt={language.cart} />;
            } else if (client_theme == 1) {
              return <img src={shoppingBag} alt={language.cart} />;
            } else if (client_theme == 2 || client_theme == 4) {
              return <img src={shoppingBagDark} alt={language.cart} />;
            } else {
              return <img src={shoppingBag} alt={language.cart} />;
            }
          })()}
          <span className="hcart_round">
            {this.props.cateringCartTotalItmCount}
          </span>
        </a>
      );
    } else {
      return (
        <>
          <a
            href="#"
            className={!currenturl.includes(substring1) && "hcartdd_trigger"}
            title=""
          >
            {(() => {
              if (client_theme == 0) {
                return <img src={shoppingBag} alt={language.cart} />;
              } else if (client_theme == 1) {
                return <img src={shoppingBag} alt={language.cart} />;
              } else if (client_theme == 2 || client_theme == 4) {
                return <img src={shoppingBagDark} alt={language.cart} />;
              } else {
                return <img src={shoppingBag} alt={language.cart} />;
              }
            })()}
            <span className="hcart_round">{this.props.cartTotalItmCount}</span>
          </a>
          <input
            type="hidden"
            id="totalitmcount"
            value={this.props.cartTotalItmCount}
          />
          {this.cartDetailsList()}
        </>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
    updateCartResponse: updateCartResponse,
    outletsubcharge: state.outletsubcharge,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },

    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getOutletSurchargeDetail: (order_delivery_date, deliveryTime) => {
      dispatch({
        type: GET_OUTLET_SUBCHARGE,
        order_delivery_date,
        deliveryTime,
      });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(CartSideBar);
