/* eslint-disable */
import React from "react";
import ReactTooltip from "react-tooltip";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import thnkyou_tick_img from "../../common/images/tick.png";
import scootyImg from "../../common/images/scooty.png";
import cartMpImg from "../../common/images/cart-map.png";
import { GET_CATERINGHISTORY, GET_STATIC_BLOCK } from "../../actions";
import {
  getTimeobject,
  showPriceValue,
  stripslashes,
  addressFormat,
  getSurchargesplitup,
  showPriceValueNew,
} from "../Helpers/SettingHelper";
import { CountryTxt, language } from "../Helpers/Config";

var Parser = require("html-react-parser");

class Thankyou extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props, "this.props");
    this.state = {
      cartDetailsMain: [],
      startDate: new Date(),
      flag: "no",
      billing_add_postalcode: "",
      delivery_add_unitno: "",
      globalSettings: [],
    };
    this.props.getStaticblock();
    var historyparams =
      "&local_order_no=" +
      this.props.match.params.orderId +
      "&customer_id=" +
      cookie.load("UserId");
    this.props.getCateringThankHistory(historyparams);
  }

  componentWillReceiveProps(nxtProps) {
    if (this.state.cartData !== nxtProps.cateringOrderData) {
      this.setState(
        {
          status: "ok",
          cartItems: nxtProps.cateringOrderItems,
          cartData: nxtProps.cateringOrderData,
        },
        function () {
          this.makeBrkTimeList();
        }.bind(this)
      );
    }
  }

  makeBrkTimeList() {
    var cartDetailsSate = this.state.cartData;
    var cartItems = this.state.cartItems;

    var cartBrkTmList = [];
    if (
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      var breaktimeCount = cartDetailsSate.order_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = [];
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = [];
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].item_breaktime_indexflag,
              break_time_start: cartItems[key].item_breaktime_started,
              break_time_end: cartItems[key].item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }
    this.setState({ cart_brktime_list: cartBrkTmList });

    // setTimeout(function(){ if ($(".mCustomScrollbar").length > 0) {
    //   $(".mCustomScrollbar").mCustomScrollbar();
    //   } }, 800);
  }

  showUnitNumber(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";
    var unitNo = "";
    if (unit1 !== "") {
      unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  getCartDetList() {
    var cartDetailsSate = this.state.cartData;
    var cartBrktimeLst = this.state.cart_brktime_list;
    if (
      cartDetailsSate !== undefined &&
      cartDetailsSate !== null &&
      Object.keys(cartDetailsSate).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      if (
        breaktimeEnable === "Yes" &&
        cartBrktimeLst !== null &&
        Object.keys(cartBrktimeLst).length > 0
      ) {
        return this.cartBrkTimeList();
      } else {
        return this.cartListOnly();
      }
    }
  }

  cartBrkTimeList() {
    var cartBrktimeLst = this.state.cart_brktime_list;
    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => {
      var count = indx + 1;
      return (
        <div>
          <div className="order-details-with-clear">
            <h5>
              {language.orderdetails} {count}
            </h5>
          </div>
          <div className="order_card_body">
            <div className="event_timing">
              <div className="form-group">
                <div className="input_field">
                  <label>{language.eventstarttime}</label>
                  <div className="text_right">
                    {cartBrk["cart_detaile"].break_time_start}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="input_field">
                  <label>{language.eventendtime}</label>
                  <div className="text_right">
                    {cartBrk["cart_detaile"].break_time_end}
                  </div>
                </div>
              </div>
            </div>

            <div className="products_info">
              {this.cartItemlist(cartBrk["cart_items"], indx)}
            </div>
          </div>
        </div>
      );
    });

    return cartBrktmhtml;
  }

  getbreaktimelist(selcted) {
    var breakTimeHtml = "";
    if (Object.keys(getTimeobject).length > 0) {
      getTimeobject.map((option) => {
        if (selcted === option.value) {
          breakTimeHtml +=
            "<option value='" +
            option.value +
            "' selected='selected'>" +
            option.label +
            "</option>";
        } else {
          breakTimeHtml +=
            "<option value='" +
            option.value +
            "' >" +
            option.label +
            "</option>";
        }
      });
    }
    return breakTimeHtml;
  }

  cartListOnly() {
    var cartItems = this.state.cartItems;
    return (
      <div className="order_card_body">{this.cartItemlist(cartItems)}</div>
    );
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1} className="cart-package-info">
          {/* package div start */}
          <div className="products_info_item product-details">
            <div className="cart_left">
              <h4>{stripslashes(cartLst.item_name)}</h4>
              <div className="products_info_options">
                <div className="products_info_options_wrap">
                  {this.loadModifierItems(
                    "Component",
                    cartLst.modifiers,
                    cartLst.set_menu_component,
                    cartLst.item_qty,
                    cartLst.item_unit_price,
                    cartLst.item_id,
                    cartLst.item_product_id,
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="cart_right text-right">
              <span
                class="cart_indvl_price"
                data-carttotl={cartLst.cart_item_unit_price}
              >
                {showPriceValueNew(cartLst.item_total_amount)}
              </span>
            </div>
          </div>
          <div className="cart-settup-list">
            <ul>
              {this.checkSettup(cartLst.setup, "Buffet") && (
                <li>
                  <div className="pkg-list-lhs">
                    <label>{language.buffet}:</label>
                    <div>
                      <span className="price">
                        {showPriceValueNew(
                          this.settuptotalPrice(cartLst.setup, "Buffet")
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Buffet")}
                  </div>
                </li>
              )}
              {this.checkSettup(cartLst.setup, "Delivery") && (
                <li>
                  <div className="pkg-list-lhs">
                    <label>{language.delivery}:</label>
                    <div>
                      <span className="price">
                        {showPriceValueNew(
                          this.settuptotalPrice(cartLst.setup, "Delivery")
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Delivery")}
                  </div>
                </li>
              )}
              {this.checkSettup(cartLst.setup, "Self Collection") && (
                <li>
                  <div className="pkg-list-lhs">
                    <label>{language.selfcollection}:</label>
                    <div>
                      <span className="price">
                        {showPriceValueNew(
                          this.settuptotalPrice(
                            cartLst.setup,
                            "Self Collection"
                          )
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.setup, "Self Collection")}
                  </div>
                </li>
              )}
              {cartLst.equipment !== "" &&
                cartLst.equipment !== undefined &&
                cartLst.equipment.length > 0 && (
                  <li>
                    <div className="pkg-list-lhs">
                      <label>{language.equipment}:</label>
                      <div>
                        <span className="price">
                          {showPriceValueNew(
                            this.settuptotalPrice(
                              cartLst.equipment,
                              "Equipment"
                            )
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="pkgslist_addition">
                      {this.loadSettup(cartLst.equipment, "Equipment")}
                    </div>
                  </li>
                )}
              {cartLst.addons_setup !== "" &&
                cartLst.addons_setup !== undefined &&
                cartLst.addons_setup.length > 0 && (
                  <li>
                    <label>{language.addon}:</label>
                    <div className="pkgslist_addition">
                      {this.loadSettup(cartLst.addons_setup, "Addons")}
                    </div>
                  </li>
                )}
            </ul>
          </div>
          {/* package div end */}
          {/* (cartLst.setup) ? this.setupDet(cartLst.setup) : '' */}
        </div>
      ));

      return cartlsthtml;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["menu_product_qty"]) > 0) {
          var comboPro = combos[r]["menu_product_name"];
          var comboQty = combos[r]["menu_product_qty"];
          var comboPrice = combos[r]["menu_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
          if (combos[r].modifiers.length > 0) {
            html += this.showComboModifiersValues(combos[r]);
          }
        }
      }
      return html;
    }
    return "";
  }

  showComboModifiersValues(product_details) {
    var mod_length = product_details.modifiers.length;
    var html = " ";
    for (var m = 0, leng = mod_length; m < leng; m++) {
      // var cart_mod_name = product_details.modifiers[m].order_modifier_name;
      var mod_val_leng = product_details.modifiers[m].modifiers_values.length;
      //html += "<div class='modifier_values_list_wrap'><p>" + cart_mod_name + "</p>";
      html += "<div class='modifier_values_list_wrap'>";
      html += "<div class='modifier_values_list'>";
      for (var v = 0, lenth = mod_val_leng; v < lenth; v++) {
        var cart_modifier_name =
          product_details.modifiers[m].modifiers_values[v].order_modifier_name;
        var cart_modifier_price =
          product_details.modifiers[m].modifiers_values[v].order_modifier_price;
        cart_modifier_price =
          parseInt(cart_modifier_price) > 1
            ? "(" + showPriceValueNew(cart_modifier_price) + ")"
            : "";
        var order_modifier_qty =
          product_details.modifiers[m].modifiers_values[v].order_modifier_qty;
        var order_modifier_qtyval =
          parseInt(order_modifier_qty) > 1 ? order_modifier_qty + " X " : "";
        html +=
          "<p>" +
          order_modifier_qtyval +
          cart_modifier_name +
          "&nbsp;" +
          cart_modifier_price +
          "</p>";
      }

      html += "</div></div>";
    }

    return html;
  }

  loadModifierItems(
    itemType,
    modifiers,
    combo,
    cart_item_qty,
    cart_item_unit_price,
    cart_item_id,
    cart_item_product_id,
    cart_item_min_qty
  ) {
    var len = modifiers.length;
    if (combo !== undefined) {
      var comboLen = combo.length;
    }
    var html = "";

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+" + modVlPrice + ")" : "";
        temp_html +=
          "<p><b>" +
          modName +
          ":</b></p> <p>" +
          modval +
          newModVlPrice +
          "</b></p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var j = 0, length = comboLen; j < length; j++) {
        var comboName = combo[j]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[j]["product_details"]);
        if (combo[j]["product_details"][0]["modifiers"].length) {
          html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + "</p> ";
        } else {
          html += "<p><b>" + comboName + ":</b> </p><p>" + comboVal + "</p> ";
        }
      }
      var reactElement1 = Parser(html);
      return reactElement1;
    }
  }

  setupDet(cartLstDat) {
    if (cartLstDat && Object.keys(cartLstDat).length > 0) {
      var setupDt = cartLstDat;
      var setupLbl = "";
      var setupPrice = 0;
      for (var key in setupDt) {
        setupLbl = setupDt[key].os_setup_type;
        setupPrice += parseFloat(setupDt[key].os_setup_total_price);
      }

      return (
        <div className="buffet_setup">
          <div className="row">
            <div className="col-sm-6 col-xs-9">
              <h5>
                <a href="javascript:void(0)">
                  <span className="title">
                    {setupLbl} {language.setup}
                  </span>
                </a>
              </h5>
            </div>
            <div className="col-sm-6 col-xs-3">
              {/*<div className="amt">
              <span>{showPriceValue(setupPrice)}</span>
          </div>*/}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 col-xs-10">{this.setupList(setupDt)}</div>
          </div>
        </div>
      );
    }
  }

  loadSettup(setup, type) {
    let check_settup = this.checkSettup(setup, type);
    if (type === "Equipment") {
      return setup.map((settup, index) => {
        return (
          <p key={index}>
            {settup.oe_equipment_qty} X {settup.oe_equipment_description} (+
            {showPriceValueNew(settup.oe_equipment_price)})
          </p>
        );
      });
    } else if (type === "Addons") {
      return setup.map((settup, index) => {
        return (
          <div className="pkgslist_additionrow" key={index}>
            <div className="pkg-list-lhs">
              <label>{settup.as_setup_title}</label>
              <div>
                <span className="price">
                  {showPriceValueNew(settup.as_setup_amount)}
                </span>
              </div>
            </div>
            <div className="pkgslist_subaddition">
              {this.loadAddonValues(settup.addons_setup_values)}
            </div>
          </div>
        );
      });
    } else {
      if (check_settup) {
        return setup.map((settup, index) => {
          if (settup.os_setup_type === type) {
            return (
              <p key={index}>
                {settup.os_setup_name} (
                {showPriceValueNew(settup.os_setup_total_price)}){" "}
              </p>
            );
          }
        });
      }
    }
  }

  checkSettup(setup, type) {
    let count = 0;
    setup.map((settup) => {
      if (settup.os_setup_type === type) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }

  loadAddonValues(AddonValues) {
    return AddonValues.map((addonval, index) => {
      return (
        <p key={index}>
          {addonval.asv_setup_val_title} ({addonval.asv_setup_val_qty}X)
        </p>
      );
    });
  }

  settuptotalPrice(setup, type) {
    let totalPrice = 0;
    if (type === "Equipment") {
      setup.map((settup) => {
        totalPrice += parseFloat(settup.oe_equipment_total_price);
      });
    } else {
      setup.map((settup) => {
        if (settup.os_setup_type === type) {
          totalPrice += parseFloat(settup.os_setup_total_price);
        }
      });
    }
    return totalPrice;
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {stripslashes(setupVl.os_setup_name)} :{" "}
          {stripslashes(setupVl.os_setup_description)}{" "}
          {parseFloat(setupVl.os_setup_total_price) > 0
            ? " ( " + showPriceValue(setupVl.os_setup_total_price) + " )"
            : ""}
        </p>
      ));
      return setupDathtml;
    }
  }

  render() {
    var orderDetail = [];
    if (this.state.cartData) {
      orderDetail = this.state.cartData;
    }

    let order_kitchen_remarks = {};
    if (orderDetail.order_kitchen_remarks) {
      order_kitchen_remarks = JSON.parse(orderDetail.order_kitchen_remarks);
    }

    let {
      sur_deliverysetup_fee,
      sur_ear_deliverysetup_fee,
      sur_lat_teardown_fee,
      sur_deliverysetup_fee_name,
      sur_ear_deliverysetup_fee_name,
    } = getSurchargesplitup(
      orderDetail.order_subcharge_amount,
      order_kitchen_remarks
    );

    let staticblockData = [];
    if (this.props.staticblockData) {
      staticblockData = this.props.staticblockData.result_set;
    }
    console.log(orderDetail, "orderDetailorderDetail");
    return (
      <div className="thankyou-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>{language.thankyou}</p>
          </div>
        </div>

        {/* checkout section */}
        <div className="container">
          {/* banner */}
          {Object.keys(orderDetail).length > 0 && (
            <div className="innersection_wrap tnk-you">
              <div className="mainacc_toptext tick">
                <img src={thnkyou_tick_img} alt="" />
                <h2>{language.thankyou}</h2>
                <p>{language.orderplacedsuccess}</p>
              </div>

              <div className="thank-order-detaildiv">
                <div className="tnk-detail text-center">
                  <h2>{language.yourorderdetails}</h2>
                  <div className="tnk-order">
                    <h3>
                      {language.orderno} - {orderDetail.order_local_no}
                    </h3>
                    <p>
                      {language.orderplacedat} :{" "}
                      {Moment(orderDetail.order_created_on).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </p>
                    <p>
                      {/*The Plattering Co Team will contact you on payment and confirmation of your order*/}
                      {CountryTxt} {language.contactwithinbusinessdays}
                    </p>
                  </div>
                </div>
                <div className="tnk-delivery">
                  <div className="delivery-cart-div">
                    <div className="cart-direction">
                      <img className="cart-direction-left" src={scootyImg} />
                      <img className="cart-direction-right" src={cartMpImg} />
                    </div>
                    <div className="cart_row tnkorder-first">
                      <div className="order-hlhs text-left">
                        <h5>{language.orderhandlingby}</h5>
                        <p>
                          {orderDetail.outlet_name !== "" &&
                            orderDetail.outlet_name !== undefined && (
                              <p>{stripslashes(orderDetail.outlet_name)}</p>
                            )}
                        </p>
                        <p>
                          {addressFormat(
                            orderDetail.outlet_unit_number1,
                            orderDetail.outlet_unit_number2,
                            orderDetail.outlet_address_line1,
                            orderDetail.outlet_address_line2,
                            orderDetail.outlet_postal_code
                          )}
                        </p>
                      </div>
                      <div className="order-hrhs text-right">
                        <h5>{language.cateringlocation}</h5>
                        {orderDetail.order_hall_name !== "" ? (
                          <p>{stripslashes(orderDetail.order_hall_name)}</p>
                        ) : (
                          ""
                        )}
                        <p>
                          {addressFormat(
                            orderDetail.order_customer_unit_no1,
                            orderDetail.order_customer_unit_no2,
                            orderDetail.order_customer_address_line1,
                            orderDetail.order_customer_address_line2,
                            orderDetail.order_customer_postal_code
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="delivery_total delivery_total_number delivery_datetime_div">
                    <div className="delivery_total_left">
                      <h2>{language.cateringlocation}</h2>
                      <h4 className="checkoutDate">
                        {Moment(orderDetail.order_date).format("DD/MM/YYYY")}
                      </h4>
                    </div>
                    {/* <div className="delivery_total_left delivery_total_right">
                      <h2>Delivery Time</h2>
                      <h4 className="checkoutTime">
                        {Moment(orderDetail.order_date).format("hh:mm A")}
                      </h4>
                    </div> */}
                    <div className="note">
                      <b>{language.note}: </b>
                      {CountryTxt} {language.teamwillarrivemins}
                    </div>
                  </div>
                  <div className="orderitem_body_div">
                    <div className="overall-parent">
                      {this.getCartDetList()}
                    </div>
                  </div>

                  <div className="cart_footer tnk_cart_footer">
                    <div className="cart_row">
                      <p className="text-uppercase">{language.subtotal}</p>

                      <span>
                        {this.state.cartData
                          ? showPriceValueNew(
                              this.state.cartData.order_sub_total
                            )
                          : ""}
                      </span>
                    </div>
                    {this.state.cartData.order_discount_applied === "Yes" && (
                      <div className="cart_row">
                        <p className="text-uppercase">{language.discount}</p>

                        <span>
                          {this.state.cartData &&
                          this.state.cartData.order_discount_applied === "Yes"
                            ? showPriceValueNew(
                                this.state.cartData.order_discount_amount
                              )
                            : ""}
                        </span>
                      </div>
                    )}

                    {(parseFloat(orderDetail.order_delivery_charge) > 0 ||
                      parseFloat(orderDetail.order_additional_delivery) >
                        0) && (
                      <div className="cart_row">
                        <p className="text-uppercase">
                          {language.locationsurcharge}
                        </p>
                        <span>
                          {showPriceValueNew(
                            parseFloat(orderDetail.order_delivery_charge) +
                              parseFloat(orderDetail.order_additional_delivery)
                          )}
                        </span>
                      </div>
                    )}
                    {parseFloat(orderDetail.order_subcharge_amount) > 0 &&
                      sur_deliverysetup_fee > 0 && (
                        <div className="cart_row">
                          <p className="text-uppercase">
                            {language.deliverysetupfee}{" "}
                            {sur_deliverysetup_fee_name}
                          </p>
                          <span>
                            {showPriceValueNew(sur_deliverysetup_fee)}
                          </span>
                        </div>
                      )}
                    {parseFloat(orderDetail.order_subcharge_amount) > 0 &&
                      sur_ear_deliverysetup_fee > 0 && (
                        <div className="cart_row">
                          <p className="text-uppercase">
                            {language.earlysetupfee}{" "}
                            {sur_ear_deliverysetup_fee_name}
                          </p>
                          <span>
                            {showPriceValueNew(sur_ear_deliverysetup_fee)}
                          </span>
                        </div>
                      )}
                    {parseFloat(orderDetail.order_subcharge_amount) > 0 &&
                      sur_lat_teardown_fee > 0 && (
                        <div className="cart_row">
                          <p className="text-uppercase">
                            {language.lateteardownfee}
                          </p>
                          <span>{showPriceValueNew(sur_lat_teardown_fee)}</span>
                        </div>
                      )}
                    {parseFloat(orderDetail.order_tax_charge) > 0 && (
                      <div className="cart_row gst-row">
                        <p className="text-uppercase">
                          {language.gst} (
                          {parseInt(orderDetail.order_tax_charge)}%)
                        </p>
                        <span>
                          {showPriceValueNew(
                            orderDetail.order_tax_calculate_amount
                          )}
                        </span>
                      </div>
                    )}
                    <div className="cart_row grant-total-cls">
                      <p className="text-uppercase">{language.total} </p>
                      <span>
                        {this.state.cartData
                          ? showPriceValueNew(
                              this.state.cartData.order_total_amount
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="tnk-chk-order">
                    <Link to={"/myorders"} className="button">
                      {language.checkorderstatus}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <ReactTooltip />
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCateringThankHistory: (historyparams) => {
      dispatch({ type: GET_CATERINGHISTORY, historyparams });
    },
    getStaticblock: (staticblock_list) => {
      dispatch({
        type: GET_STATIC_BLOCK,
        staticblock_list:
          "footer-contact-info,footer-copyright-info,package-banner",
      });
    },
  };
};

const mapStateToProps = (state) => {
  var resultSetArr = [];
  var orderStatus = "";
  var orderData = [];
  var orderItems = [];
  if (
    state.cateringhistory !== undefined &&
    Object.keys(state.cateringhistory).length > 0
  ) {
    var resultSetArr = !("result_set" in state.cateringhistory[0])
      ? []
      : state.cateringhistory[0].result_set;
    if (
      state.cateringhistory[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      orderData = resultSetArr[0];
      orderItems = resultSetArr[0]["items"];
      orderStatus = "yes";
    } else {
      orderStatus = "no";
    }
  }

  return {
    cateringOrderData: orderData,
    cateringOrderItems: orderItems,
    cateringOrderStatus: orderStatus,
    staticblockData: state.staticblockData,
  };
};

Thankyou = connect(mapStateToProps, mapDispatchToProps)(Thankyou);

export default withRouter(Thankyou);
