/* eslint-disable */
var location_ULR = location.href;
if (location.hostname !== "localhost") {
  if (location.protocol !== "https:") {
    if (
      location_ULR.indexOf("order.") >= 0 ||
      location_ULR.indexOf("orders.") >= 0 ||
      location_ULR.indexOf("ninjaent.") >= 0 ||
      location_ULR.indexOf("uat.") >= 0 ||
      location_ULR.indexOf("beamarry.") >= 0 ||
      location_ULR.indexOf("wholesales.") >= 0 ||
      location_ULR.indexOf("orderonline.") >= 0 ||
      location_ULR.indexOf("tableqr.") >= 0 ||
      location_ULR.indexOf("thesapling.") >= 0 ||
      location_ULR.indexOf("phplus.") >= 0 ||
      location_ULR.indexOf("menu.") >= 0 ||
      location_ULR.indexOf("grocery.") >= 0 ||
      location_ULR.indexOf("florist.") >= 0 ||
      location_ULR.indexOf("retail.") >= 0 ||
      location_ULR.indexOf("restaurant.") >= 0 ||
      location_ULR.indexOf("holland.") >= 0 ||
      location_ULR.indexOf("koomi1.") >= 0 ||
      location_ULR.indexOf("koomi2.") >= 0 ||
      location_ULR.indexOf("koomi3.") >= 0 ||
      location_ULR.indexOf("koomi4.") >= 0 ||
      location_ULR.indexOf("spize.") >= 0 ||
      location_ULR.indexOf("srisun.") >= 0 ||
      location_ULR.indexOf("abundance.") >= 0 ||
      location_ULR.indexOf("maixiang.") >= 0 ||
      location_ULR.indexOf("proplus.") >= 0 ||
      location_ULR.indexOf("hungrybbq.") >= 0 ||
      location_ULR.indexOf("timhowan.") >= 0 ||
      location_ULR.indexOf("bigfishsmallfish.") >= 0 ||
      location_ULR.indexOf("tamjaisamgor.") >= 0 ||
      location_ULR.indexOf("order-for-delivery-ohs.") >= 0 ||
      location_ULR.indexOf("carnival.") >= 0
    ) {
      var locationULR = location_ULR.replace("www.", "");
      location.href = locationULR.replace("http://", "https://");
    } else {
      var locationULR = location.href.replace("www.", "");
      location.href = locationULR.replace("http://", "https://www.");
    }
  } else if (location.protocol === "https:") {
    var splitURL = location_ULR.split("//");
    var splitDomain = [];
    if (splitURL.length > 0) {
      var splitDomain = splitURL[1].split(".");
    }
    var subDomainList = [
      "order",
      "orders",
      "ninjaent",
      "uat",
      "beamarry",
      "wholesales",
      "orderonline",
      "tableqr",
      "thesapling",
      "phplus",
      "menu",
      "grocery",
      "florist",
      "retail",
      "restaurant",
      "holland",
      "koomi1",
      "koomi2",
      "koomi3",
      "koomi4",
      "spize",
      "srisun",
      "abundance",
      "maixiang",
      "proplus",
      "hungrybbq",
      "timhowan",
      "bigfishsmallfish",
      "tamjaisamgor",
      "order-for-delivery-ohs",
      "carnival",
    ];

    var locationULR = location_ULR;
    if (
      location_ULR.indexOf("order.") >= 0 ||
      location_ULR.indexOf("orders.") >= 0 ||
      location_ULR.indexOf("ninjaent.") >= 0 ||
      location_ULR.indexOf("uat.") >= 0 ||
      location_ULR.indexOf("beamarry.") >= 0 ||
      location_ULR.indexOf("wholesales.") >= 0 ||
      location_ULR.indexOf("orderonline.") >= 0 ||
      location_ULR.indexOf("tableqr.") >= 0 ||
      location_ULR.indexOf("thesapling.") >= 0 ||
      location_ULR.indexOf("phplus.") >= 0 ||
      location_ULR.indexOf("menu.") >= 0 ||
      location_ULR.indexOf("grocery.") >= 0 ||
      location_ULR.indexOf("florist.") >= 0 ||
      location_ULR.indexOf("retail.") >= 0 ||
      location_ULR.indexOf("restaurant.") >= 0 ||
      location_ULR.indexOf("holland.") >= 0 ||
      location_ULR.indexOf("koomi1.") >= 0 ||
      location_ULR.indexOf("koomi2.") >= 0 ||
      location_ULR.indexOf("koomi3.") >= 0 ||
      location_ULR.indexOf("koomi4.") >= 0 ||
      location_ULR.indexOf("spize.") >= 0 ||
      location_ULR.indexOf("srisun.") >= 0 ||
      location_ULR.indexOf("abundance.") >= 0 ||
      location_ULR.indexOf("maixiang.") >= 0 ||
      location_ULR.indexOf("proplus.") >= 0 ||
      location_ULR.indexOf("hungrybbq.") >= 0 ||
      location_ULR.indexOf("timhowan.") >= 0 ||
      location_ULR.indexOf("bigfishsmallfish.") >= 0 ||
      location_ULR.indexOf("tamjaisamgor.") >= 0 ||
      location_ULR.indexOf("order-for-delivery-ohs.") >= 0 ||
      location_ULR.indexOf("carnival.") >= 0
    ) {
      if (subDomainList.indexOf(splitDomain[0]) < 0) {
        var locationULR = location_ULR.replace("www.", "");
        location.href = locationULR.replace("http://", "https://");
      }
    } else {
      if (locationULR.indexOf("www.") < 0) {
        location.href = "https://www." + locationULR.replace("https://", "");
      }
    }
  }
}

import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "@koomipay/react-legacy/dist/koomipay.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import { appId } from "./components/Helpers/Config";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import FavouriteProducts from "./components/Products/FavouriteProducts";
import ProductDetail from "./components/Products/ProductDetail";
import FutureProducts from "./components/Products/FutureProducts";
import PromotionProducts from "./components/Products/PromotionProducts";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Findingdriver from "./components/Checkout/Findingdriver";
import Payment from "./components/Payment/Payment";
import PaymentThankyou from "./components/Payment/Thankyou";
import Pages from "./components/Pages/Pages";
import Faq from "./components/Pages/Faq";
import Aboutus from "./components/Pages/Aboutus";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Myvoucher from "./components/Myaccount/Myvoucher";
import ReferFriends from "./components/Myaccount/ReferFriends";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Scanqrcode from "./components/Dineqrcode/Scanqrcode";
import Booktable from "./components/Dineqrcode/Booktable";
import Myreservation from "./components/Myaccount/Myreservation";
import Referral from "./components/Myaccount/Referral";
import Rfcode from "./components/Layout/Rfcode";
/* import Catering from "./components/Catering/Catering";
import Packagecheckout from "./components/Catering/Packagecheckout"; */

/* import Catering from "./components/Catering/Catering";
import CateringThankyou from "./components/Catering/Thankyou";
import Viewmenu from "./components/Catering/Viewmenu"; */

import Maincategory from "./components/Catering/Maincategory";
import Catering from "./components/Catering/index";
import Editcart from "./components/Catering/Editcart";
import Cateringsuccess from "./components/Catering/Refpage";
import Cateringcheckout from "./components/Cateringcheckout/index";
import CateringThankyou from "./components/CateringThankyou/index";

import Reservation from "./components/Reservation/Reservation";
import Reservationsummary from "./components/Reservation/Reservationsummary";
import Reservationthankyou from "./components/Reservation/Reservationthankyou";
import Reservationsuggestion from "./components/Reservation/Reservationsuggestion";
import Placeorder from "./components/Checkout/Placeorder";
import Fomoplaceorder from "./components/Checkout/Fomoplaceorder";
import Failed from "./components/Checkout/Failed";

import Ewalletplaceorder from "./components/Checkout/Ewalletplaceorder";
import Ewalletfailed from "./components/Checkout/Ewalletfailed";

import Membershipinfographic from "./components/Pages/Membershipinfographic";

import KoomipayComplete from "./components/Checkout/KoomipayComplete";

import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";
import cookie from "react-cookies";
import TagManager from "react-gtm-module";
const store = getStore();

var isSubdomain = function (url) {
  var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  return !!url.match(regex);
};

var isSubdomains = function (url) {
  return url.split(".").length > 2;
};

/*if (
  !window.location.host.startsWith("www") &&
  !isSubdomain(window.location.host) &&
  !isSubdomains(window.location.host)
) {
  window.location =
    window.location.protocol +
    "//" +
    "www." +
    window.location.host +
    window.location.pathname;
}*/

if (appId === "51B0D9F2-1EA0-4308-B339-190D8AE3CD41") {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=UA-226386388-1";
  script.async = true;
  document.head.appendChild(script);

  const script1 = document.createElement("script");
  script1.type = "text/javascript";
  script1.async = true;
  script1.innerHTML =
    "window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-226386388-1');";
  document.head.appendChild(script1);
} else if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-76CE32RBCN";
  script.async = true;
  document.head.appendChild(script);

  const script1 = document.createElement("script");
  script1.type = "text/javascript";
  script1.async = true;
  script1.innerHTML =
    "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-76CE32RBCN');";
  document.head.appendChild(script1);

  const script2 = document.createElement("script");
  script2.src = "https://www.googletagmanager.com/gtag/js?id=AW-10862588258";
  script2.async = true;
  document.head.appendChild(script2);

  const script3 = document.createElement("script");
  script3.type = "text/javascript";
  script3.async = true;
  script3.innerHTML =
    "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10862588258');";
  document.head.appendChild(script3);

  const script4 = document.createElement("script");
  script4.type = "text/javascript";
  script4.async = true;
  script4.innerHTML =
    "gtag('event', 'conversion', {'send_to': 'AW-10862588258/yA3ICLvd0bYDEOLi17so'}); ";
  document.head.appendChild(script4);
} else if (appId === "98C0601F-F797-4822-9443-508373FA625B") {
  const script1 = document.createElement("script");
  script1.type = "text/javascript";
  script1.async = true;
  script1.innerHTML =
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-KV6K24J');";
  document.head.appendChild(script1);

  const script2 = document.createElement("noscript");
  script2.async = true;
  script2.innerHTML =
    '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KV6K24J" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
  document.body.appendChild(script2);
} else if (appId === "10B5935B-D3D6-402F-8EE2-81D351779E36") {
  const script1 = document.createElement("script");
  script1.type = "text/javascript";
  script1.async = true;
  script1.innerHTML =
    "!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '1085965135721990'); fbq('track', 'PageView');";
  document.head.appendChild(script1);

  const script2 = document.createElement("noscript");
  script2.async = true;
  script2.innerHTML =
    '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1085965135721990&ev=PageView&noscript=1" />';
  document.body.appendChild(script2);
}

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path={"/products/:slugType/:slugValue/:proValue"}
          component={Products}
        />
        <Route path={"/products/:slugType/:slugValue"} component={Products} />
        <Route path="/products" component={Products} />
        <Route
          path={"/menu/:slugType/:slugValue/:proValue/:tableNumber"}
          component={ProductDetail}
        />
        <Route
          path={"/menu/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/menu/:slugType/:slugValue"} component={Products} />
        <Route path="/menu" component={Products} />
        <Route path="/favourite" component={FavouriteProducts} />
        <Route path="/future-products" component={FutureProducts} />
        <Route path="/promotion-products" component={PromotionProducts} />
        {/* <Route path="/package/checkout" component={Packagecheckout} /> */}
        {/* <Route path="/package" component={Package} />  */}
        {/* <Route path="/package" component={Catering} /> */}
        {/* <Route
          path="/catering/thankyou/:orderId"
          component={CateringThankyou}
        />
        <Route path="/catering" component={Catering} />
        <Route path="/viewmenu" component={Viewmenu} /> */}

        <Route path="/catering-checkout" component={Cateringcheckout} />
        {/* <Route path="/catering/payment/thankyou" component={PyamentThankyou} /> */}
        <Route
          path="/catering/thankyou/:orderId"
          component={CateringThankyou}
        />
        <Route path="/payment/:orderId/:paymentId" component={Payment} />
        <Route
          path="/catering/payment/thankyou/:orderId"
          component={PaymentThankyou}
        />
        <Route path="/catering/edit/:productId/:cartID" component={Catering} />
        <Route
          path="/catering-success/:catSlug/:subCatSlug"
          component={Cateringsuccess}
        />
        <Route path="/catering/:catSlug/:subCatSlug" component={Catering} />
        <Route path="/catering" component={Maincategory} />
        <Route
          path="/checkout/koomipay-complete"
          component={KoomipayComplete}
        />
        <Route path="/checkout" component={Checkout} />
        <Route path="/ewalletplaceorder" component={Ewalletplaceorder} />
        <Route path="/ewalletfailed" component={Ewalletfailed} />
        <Route path="/ewalletcancelled" component={Ewalletfailed} />
        <Route path="/scanqrcode" component={Scanqrcode} />
        <Route path="/quickbook/:tablenumber" component={Booktable} />
        <Route path="/placeorder" component={Placeorder} />
        <Route path="/fomoplaceorder" component={Fomoplaceorder} />
        <Route path="/failed" component={Failed} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/findingdriver/:orderId" component={Findingdriver} />
        <Route path="/payment/thankyou" component={PaymentThankyou} />
        <Route path="/payment/:orderId/:paymentId" component={Payment} />
        <Route path="/membership" component={Membershipinfographic} />
        <Route path="/our-story" component={Pages} />
        <Route path="/terms-and-conditions" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/promotions" component={Pages} />
        <Route path="/seasonal-promotions" component={Pages} />
        <Route path="/blog" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/faq" component={Faq} />
        <Route path="/about-us" component={Aboutus} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/reservation" component={Reservation} />
        <Route path="/reservation-summary" component={Reservationsummary} />
        <Route path="/reservation-thankyou" component={Reservationthankyou} />
        <Route path="/locations" component={Outlets} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myorders/:tab" component={Orders} />
        <Route path="/myorders" component={Orders} />
        <Route path="/mycateringorders" component={Orders} />
        <Route path="/myreservations" component={Myreservation} />
        <Route path="/myvouchers" component={Myvoucher} />
        <Route path="/referfriends" component={ReferFriends} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/referral" component={Referral} />
        <Route path={"/rfcode/:slugtext"} component={Rfcode} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/reservation_suggest/:acceptreject/:resid"
          component={Reservationsuggestion}
        />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route
          path={"/refpage/:slugtext/:slugtext1/:slugtext2/:slugtext3"}
          component={Refpage}
        />
        <Route
          path={"/refpage/:slugtext/:slugtext1/:slugtext2/"}
          component={Refpage}
        />
        <Route path={"/refpage/:slugtext/:slugtext1/"} component={Refpage} />
        <Route path={"/refpage/:slugtext/"} component={Refpage} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route path={"/chopchoptracking/token/:tokenID"} component={Home} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
