/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import cookie from "react-cookies";
import Changepassword from "./Changepassword";
import { GET_ACTIVITYCOUNT, GET_CHANGEPASSWORD } from "../../actions";
import { appId, language, reservationId } from "../Helpers/Config";
import { showAlert, showLoader, hideLoader } from "../Helpers/SettingHelper";
var qs = require("qs");
import { includes } from "lodash";
var currentAppId = [
  "93EDF976-7242-4864-B57E-7B49306521AC",
  "E66D5AAF-9077-4885-B379-02D3FCB8BB16",
];

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
      deliveryTakeaway_orders: "",
      reservation_orders: "",
      fieldschpassword: {
        oldpin: "",
        newpin: "",
        confirmpin: "",
      },
      globalSettings: [],
      reservation_tab_enable: 0,
      referral_enable: 0,
    };
    const inputKeys = [];
    this.props.getActivityCount();
    this.changepassword = this.changepassword.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.globalSettings !== nextProps.settingsArr) {
      if (Object.keys(nextProps.settingsArr).length > 0) {
        var availability_list = nextProps.settingsArr.availability;

        var client_referral_enable =
          nextProps.settingsArr.client_referral_enable;

        var reservation_enable = "0";

        availability_list.map((availability, index) => {
          if (reservationId === availability.availability_id) {
            reservation_enable = "1";
          }
        });

        this.setState({
          globalSettings: nextProps.settingsArr,
          reservation_tab_enable: reservation_enable,
          referral_enable: client_referral_enable,
        });
      }
    }

    /*activity count -start */
    if (Object.keys(nextProps.activitycountNew).length > 0) {
      if (nextProps.activitycountNew !== this.props.activitycountNew) {
        if (
          nextProps.activitycountNew[0].status &&
          nextProps.activitycountNew[0].result_set
        ) {
          this.setState({
            deliveryTakeaway_orders:
              nextProps.activitycountNew[0].result_set.order,
            reservation_orders:
              nextProps.activitycountNew[0].result_set.reservation_orders,
          });
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        catering_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }

    if (nextProps.changepassword !== this.props.changepassword) {
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (nextProps.changepassword[0].status === "ok") {
        showAlert(language.success, language.passwordchangedsuccess);
      } else {
        if (nextProps.changepassword[0].form_error) {
          var msg = nextProps.changepassword[0].form_error;
        } else {
          var msg = nextProps.changepassword[0].message;
        }

        showAlert("Error", msg.replaceAll(language.pin, language.password));
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  /* Change Password */
  changepassword() {
    var old_pin = $(".old_password").val();
    var new_pin = $(".new_password").val();
    var confirm_pin = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_pin,
      password: new_pin,
      confirmpassword: confirm_pin,
      refrence: cookie.load("UserId"),
      passwordtype: "PIN",
    };
    /*if(new_pin != confirm_pin){
      alert("Password and Confirm password did not match");
      return false;
    }else{*/
    showLoader("change-pass-sbmt", "class");
    this.props.getChangePassword(qs.stringify(postObject));
    //}
  }

  render() {
    var initialSlide = 0;
    if (this.state.activePage === "myorders") {
      initialSlide = 1;
    } else if (this.state.activePage === "rewards") {
      initialSlide = 2;
    } else if (this.state.activePage === "mypromotions") {
      initialSlide = 3;
    }
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      initialSlide: initialSlide,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };
    var selectedMenuTitle = "My Account";

    if (this.state.activePage === "myaccount") {
      selectedMenuTitle = "Account Details";
    } else if (this.state.activePage === "dashboard") {
      selectedMenuTitle = "Dashboard";
    } else if (this.state.activePage === "myorders") {
      selectedMenuTitle = "My Orders";
    } else if (this.state.activePage === "myreservations") {
      selectedMenuTitle = "My Reservations";
    } else if (this.state.activePage === "referral") {
      selectedMenuTitle = "Refer Friends";
    } else if (this.state.activePage === "rewards") {
      selectedMenuTitle = "My Rewards";
    } else if (this.state.activePage === "mypromotions") {
      selectedMenuTitle = "My Promotions";
    }

    return (
      <>
        <div className="mbacc_mslidersec mbacc_mslider ">
          {/* <Slider {...settingsMyAcc}>
            <div
              className={
                this.state.activePage === "myaccount"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myaccount" title="My Account">
                <span>Account Details</span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "myorders"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myorders" title="My Orders">
                <span>
                  Orders{" "}
                  {parseFloat(this.state.deliveryTakeaway_orders) > 0 && (
                    <strong className="count_num_t">
                      {this.state.deliveryTakeaway_orders}
                    </strong>
                  )}
                </span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "rewards"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/rewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "mypromotions"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/mypromotions" title="My Promotions">
                <span>Promotions</span>
              </Link>
            </div>
            <div className="mbacc_mslide">
              <a href="#change-password-popup" className="open-popup-link">
                Change PIN
              </a>
            </div>
            <div className="mbacc_mslide">
              <a href="/logout">Logout</a>
            </div>
          </Slider> */}

          <div className="hcategory_sec">
            <a href="/" className="hcategory_selected_text disbl_href_action">
              {" "}
              {selectedMenuTitle} <span className="hcategory_trigger"></span>
            </a>
            <div className="hcategory_menu">
              <ul className="hcategory_menulist">
                {/*  <li>
                      <Link to="/dashboard" title="Dashboard">
                        <span>Dashboard</span>
                      </Link>
                    </li> */}
                <li>
                  <Link to="/myaccount" title={language.accountdetails}>
                    <span>{language.accountdetails}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/myorders" title={language.myorders}>
                    <span>
                      {language.orders}{" "}
                      {parseFloat(this.state.deliveryTakeaway_orders) > 0 &&
                        cookie.load("userAccountType") !== "2" && (
                          <strong className="count_num_t">
                            {this.state.deliveryTakeaway_orders}
                          </strong>
                        )}
                    </span>
                  </Link>
                </li>
                {this.state.reservation_tab_enable === "1" && (
                  <li>
                    <Link to="/myreservations" title={language.myreservations}>
                      <span>
                        {language.reservations}{" "}
                        {parseFloat(this.state.reservation_orders) > 0 &&
                          cookie.load("userAccountType") !== "2" && (
                            <strong className="count_num_t">
                              {this.state.reservation_orders}
                            </strong>
                          )}
                      </span>
                    </Link>
                  </li>
                )}

                {includes(currentAppId, appId) === false &&
                  this.state.referral_enable === "1" && (
                    <li>
                      <Link to="/referral" title="Referral">
                        <span>{language.referral}</span>
                      </Link>
                    </li>
                  )}
                <li>
                  <Link to="/rewards" title={language.myrewards}>
                    <span>{language.rewards}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/mypromotions" title={language.mypromotions}>
                    <span>{language.promotions}</span>
                  </Link>
                </li>
                {includes(currentAppId, appId) === false &&
                  this.state.referral_enable === "1" && (
                    <li>
                      <Link to="/referral" title={language.referrriends}>
                        <span>{language.referrriends}</span>
                      </Link>
                    </li>
                  )}
                <li>
                  <a href="#change-password-popup" className="open-popup-link">
                    {language.changepassword}
                  </a>
                </li>
                <li>
                  <a href="/logout">{language.logout}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="accmenu_sec">
          <div className="setting_menu_list">
            <ul>
              {/*  <li
                className={
                  this.state.activePage === "dashboard" ? "active" : ""
                }
              >
                <Link to="/dashboard" title="Dashboard">
                  <span>Dashboard</span>
                </Link>
              </li> */}
              <li
                className={
                  this.state.activePage === "myaccount" ? "active" : ""
                }
              >
                <Link to="/myaccount" title={language.myaccount}>
                  <span>{language.accountdetails}</span>
                </Link>
              </li>
              <li
                className={this.state.activePage === "myorders" ? "active" : ""}
              >
                <Link to="/myorders" title={language.myorders}>
                  <span>
                    {language.orders}{" "}
                    {parseFloat(this.state.deliveryTakeaway_orders) > 0 &&
                      cookie.load("userAccountType") !== "2" && (
                        <strong className="count_num_t">
                          {this.state.deliveryTakeaway_orders}
                        </strong>
                      )}
                  </span>
                </Link>
              </li>

              {this.state.reservation_tab_enable === "1" && (
                <li
                  className={
                    this.state.activePage === "myreservations" ? "active" : ""
                  }
                >
                  <Link to="/myreservations" title={language.myreservations}>
                    <span>
                      {language.reservations}{" "}
                      {parseFloat(this.state.reservation_orders) > 0 &&
                        cookie.load("userAccountType") !== "2" && (
                          <strong className="count_num_t">
                            {this.state.reservation_orders}
                          </strong>
                        )}
                    </span>
                  </Link>
                </li>
              )}
              {/* <li
                className={this.state.activePage === "referral" ? "active" : ""}
              >
                <Link to="/referral" title="Referral">
                  <span>Referral</span>
                </Link>
              </li> */}

              <li
                className={this.state.activePage === "rewards" ? "active" : ""}
              >
                <Link to="/rewards" title={language.myrewards}>
                  <span>{language.rewards}</span>
                </Link>
              </li>
              <li
                className={
                  this.state.activePage === "mypromotions" ? "active" : ""
                }
              >
                <Link to="/mypromotions" title={language.mypromotions}>
                  <span>{language.promotions}</span>
                </Link>
              </li>
              <li
                className={
                  this.state.activePage === "myvouchers" ? "active" : ""
                }
              >
                <Link to="/myvouchers" title={language.vouchers}>
                  <span>{language.vouchers}</span>
                </Link>
              </li>

              {appId != currentAppId && this.state.referral_enable === "1" && (
                <li
                  className={
                    this.state.activePage === "referral" ? "active" : ""
                  }
                >
                  <Link to="/referral" title={language.referrriends}>
                    <span>{language.referrriends}</span>
                  </Link>
                </li>
              )}

              {cookie.load("userAccountType") !== "2" && (
                <li>
                  <a href="#change-password-popup" className="open-popup-link">
                    {language.changepassword}
                  </a>
                </li>
              )}

              <li>
                <a href="/logout">{language.logout}</a>
              </li>
            </ul>
          </div>
        </div>
        {cookie.load("userAccountType") !== "2" && (
          <div
            id="change-password-popup"
            className="white-popup mfp-hide popup_sec changepw_popup"
          >
            <div className="pouup_in">
              <div id="form-msg"></div>
              <h3 className="title1 text-center">{language.changepassword}</h3>
              <Changepassword
                fields={this.state.fieldschpassword}
                onChange={this.fieldChange}
                onValid={this.changepassword}
                onInvalid={() => console.log("Form invalid!")}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    activitycountNew: state.activitycount,
    changepassword: state.changepassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
  };
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
