/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { GET_INSTA_FEED } from "../../../actions";
import instagramImg from "../../../common/images/instagram_icon.png";
import { language } from "../../Helpers/Config";
var settingsourclient = {
  items: 6,
  nav: true,
  autoplay: true,
  dots: false,
  margin: 10,
  autoplayTimeout: 2000,
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 4,
    },
    1200: {
      items: 6,
    },
  },
};
class InstaFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insturl: "",
    };
  }

  componentWillMount() {
    this.props.getBannerList();
  }

  componentWillReceiveProps(headerProps) {
    if (Object.keys(headerProps.globalsettings).length > 0) {
      this.setState({
        insturl:
          headerProps.globalsettings[0].result_set.socialnetwork
            .socailnetwork_instagram_url,
      });
    }
  }
  render() {
    let bannerArr = this.props.instafeed;
    return (
      <section className="instapro-section">
        {Object.keys(bannerArr).length > 0 && (
          <>
            <div className="instapro-section-inner">
              <div className="container">
                <a href="https://www.instagram.com/" target="_blank">
                  <h2 className="home-title">
                    {" "}
                    <img src={instagramImg} alt={language.gallery} />
                    <span>{language.gallery}</span>
                  </h2>
                </a>
                <div className="home-instafeed">
                  <OwlCarousel options={settingsourclient}>
                    {bannerArr.map((item, index) => {
                      if (item.media_type !== "VIDEO") {
                        return (
                          <div key={index} className="home-instafeed-image">
                            <img src={item.media_url} alt={language.gallery} />
                          </div>
                        );
                      }
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="home-insta-btmlink">
                <a href={this.state.insturl} data-hover={language.followus}>
                  {language.followus}
                </a>
              </div>
            </div>
          </>
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  var instafeeds = Array();
  if (Object.keys(state.instafeed).length > 0) {
    if (state.instafeed[0]) {
      instafeeds = state.instafeed[0].data;
    }
  }

  return {
    instafeed: instafeeds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_INSTA_FEED });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(InstaFeed);
