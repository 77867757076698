/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import cookie from "react-cookies";
import moment from "moment";
import { appId, apiUrl, language } from "../Helpers/Config";
import Axios from "axios";
var base64 = require("base-64");
var qs = require("qs");

import tickImg from "../../common/images/tick.png";
import rejectImg from "../../common/images/worried.png";

class Reservationsuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = { message: "", reservation_data: [] };
    cookie.save("orderTabs", "reservation", { path: "/" });
  }

  componentDidMount() {
    let subreservationId =
      typeof this.props.match.params.resid != "undefined"
        ? this.props.match.params.resid
        : "";
    let acceptReject =
      typeof this.props.match.params.acceptreject != "undefined"
        ? this.props.match.params.acceptreject
        : "";
    $(".dvLoadrCls").show();

    var postObject = {};
    postObject = {
      app_id: appId,
      sug_reser_id: base64.decode(subreservationId),
    };

    let ajaxUrl = "";
    if (acceptReject === "accept") {
      ajaxUrl = apiUrl + "reservation/reservation_suggest_accept";
    }
    if (acceptReject === "reject") {
      ajaxUrl = apiUrl + "reservation/reservation_suggest_reject";
    }

    if (ajaxUrl) {
      Axios.post(ajaxUrl, qs.stringify(postObject)).then((res) => {
        $(".dvLoadrCls").fadeOut(2000);

        if (res.data.status === "success") {
          if (acceptReject == "accept") {
            this.setState({ acceptReject: "success" });
            this.setState({ message: language.reservationconfirm });
            this.setState({ reservation_data: res.data.result_set[0] });
            this.setState({ outletName: res.data.outlet_name });
          }
          if (acceptReject == "reject") {
            this.setState({ acceptReject: "error" });
            this.setState({ message: language.reservationcancel });
          }
        } else if (res.data.status === "error") {
          this.setState({ acceptReject: "error" });
          this.setState({
            message: language.reservationalredyconfirm,
          });
        }
      });
    }
  }

  render() {
    return (
      <div>
        <Header />
        <div className="container common-top-div catering-thankyou-page">
          <div className="innersection_wrap tnk-you">
            <div className="mainacc_toptext tick">
              {this.state.acceptReject == "success" ? (
                <div>
                  <img src={tickImg} alt="tick" />
                  <h2>{language.thankyou}</h2>
                </div>
              ) : (
                <div>
                  <img src={rejectImg} alt="recject" />
                </div>
              )}
              <p>{this.state.message}</p>
            </div>

            <div className="thank-order-detaildiv">
              {this.state.acceptReject == "success" && (
                <div>
                  <div className="tnk-detail text-center">
                    <h2 className="text-uppercase">
                      {language.yourreservationdetails}
                    </h2>
                    <div className="tnk-order">
                      <h3>
                        {language.orderno} -{" "}
                        {this.state.reservation_data.reservation_local_order_id}
                      </h3>
                      <p>
                        {language.orderplacedat} :
                        {moment(
                          this.state.reservation_data.reservation_created_on,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("DD-MM-YYYY hh:mm A")}
                      </p>
                    </div>
                  </div>

                  <div className="tnk-delivery">
                    <div className="delivery-cart-div">
                      <div className="cart_row cart-header-first">
                        <div className="reservation_order_details">
                          <div className="reservation_outlet">
                            <h4>{language.orderhandlingby}</h4>
                            <p>
                              {
                                this.state.reservation_data
                                  .reservation_outlet_name
                              }
                            </p>
                          </div>
                          <br></br>
                          <div className="reservation_user">
                            <h4>{language.numberofpax}</h4>
                            <p>
                              {
                                this.state.reservation_data
                                  .reservation_no_of_adult_pax
                              }{" "}
                              {language.adults} &{" "}
                              {
                                this.state.reservation_data
                                  .reservation_no_of_children_pax
                              }{" "}
                              {language.kids}
                            </p>
                          </div>
                          <br></br>
                          <div className="reservation_Date">
                            <h4 className="text-uppercase">{language.date}</h4>
                            <p>
                              {moment(
                                this.state.reservation_data.reservation_date
                              ).format("DD-MM-YYYY")}
                            </p>
                          </div>
                          <br></br>
                          <div className="reservation_Time">
                            <h4 className="text-uppercase">{language.time}</h4>
                            <p>
                              {moment(
                                this.state.reservation_data
                                  .reservation_start_time,
                                "HH:mm:ss"
                              ).format("hh:mm A")}{" "}
                              -{" "}
                              {moment(
                                this.state.reservation_data
                                  .reservation_end_time,
                                "HH:mm:ss"
                              ).format("hh:mm A")}
                            </p>
                          </div>
                          <br></br>
                          <div className="reservation_notes">
                            <h4>{language.specialinstruction}</h4>
                            <p>
                              {
                                this.state.reservation_data
                                  .reservation_specification
                              }
                            </p>
                          </div>
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="tnk-chk-order">
                <Link className="button" to={"/myorders/reservation"}>
                  {language.checkbookingstatus}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

export default Reservationsuggestion;
