/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import cookie from "react-cookies";
import moment from "moment";
import { language } from "../Helpers/Config";

import tickImg from "../../common/images/tick.png";

class Reservationthankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagedetail: "",
      outletName: cookie.load("reservation_outlet_name"),
      reservation_user_mobile: cookie.load("reservation_mobile_no"),
      number_of_adult: cookie.load("reservation_adult"),
      number_of_child: cookie.load("reservation_child"),
      reservation_date: cookie.load("reservation_show_date"),
      reservation_time: cookie.load("reservation_show_time"),
      reservation_end_time: cookie.load("reservation_end_time"),
      reservation_notes: cookie.load("reservation_notes"),
      reservation_order_on: cookie.load("RerservationOrderOn"),
      reservation_order_no: cookie.load("RerservationOrderlocalId"),
      reservation_submit: 1,
    };

    var orderlocalId =
      typeof cookie.load("RerservationOrderlocalId") === "undefined"
        ? ""
        : cookie.load("RerservationOrderlocalId");
    if (orderlocalId === "") {
      this.props.history.push("/");
    }
    cookie.save("orderTabs", "reservation", { path: "/" });
    cookie.remove("defaultAvilablityId");
  }

  clear_reservation_cookies() {
    cookie.remove("reservation_date");
    cookie.remove("reservation_show_date");
    cookie.remove("reservation_show_time");
    cookie.remove("reservation_time");
    cookie.remove("reservation_time_type");
    cookie.remove("reservation_adult");
    cookie.remove("reservation_child");
    cookie.remove("reservation_outlet_id");
    cookie.remove("reservation_outlet_name");
    cookie.remove("reservation_user_id");
    cookie.remove("reservation_user_fname");
    cookie.remove("reservation_user_lname");
    cookie.remove("reservation_user_name");
    cookie.remove("reservation_user_mobile");
    cookie.remove("reservation_mobile_no");
    cookie.remove("reservation_user_email");
    cookie.remove("reservation_notes");
    cookie.remove("reservation_selected_outlet");
    cookie.remove("reservation_date_array");
    cookie.remove("RerservationOrderlocalId");
    cookie.remove("defaultAvilablityId");
    this.setState({ reservation_submit: 0 });
  }

  componentDidMount() {
    this.clear_reservation_cookies();
  }

  render() {
    var show_reservation_time = moment(
      this.state.reservation_time,
      "HH:mm:ss"
    ).format("hh:mm A");

    if (
      this.state.reservation_end_time != "" &&
      this.state.reservation_end_time != "undefined"
    ) {
      show_reservation_time =
        "(" +
        moment(this.state.reservation_time, "HH:mm:ss").format("hh:mm A") +
        " - " +
        moment(this.state.reservation_end_time, "HH:mm:ss").format("hh:mm A") +
        ")";
    }

    return (
      <div>
        <Header />
        <div className="container common-top-div catering-thankyou-page">
          <div className="innersection_wrap tnk-you">
            <div className="mainacc_toptext tick">
              <img src={tickImg} alt="tick" />
              <h2>{language.thankyou}</h2>
              <p>{language.reservationmade}</p>
              <p>{language.contactanychanges}</p>
            </div>

            <div className="thank-order-detaildiv">
              <div className="tnk-detail text-center">
                <h2 className="text-uppercase">
                  {language.yourreservationdetails}
                </h2>
                <div className="tnk-order">
                  <h3>
                    {language.orderno} - {this.state.reservation_order_no}
                  </h3>
                  <p>
                    {language.orderplacedat} : {this.state.reservation_order_on}
                  </p>
                </div>
              </div>

              <div className="tnk-delivery">
                <div className="delivery-cart-div">
                  <div className="cart_row cart-header-first">
                    <div className="reservation_order_details">
                      <div className="reservation_outlet">
                        <h4>{language.orderhandlingby}</h4>
                        <p>{this.state.outletName}</p>
                      </div>
                      <br></br>
                      <div className="reservation_outlet">
                        <h4>{language.phoneno}</h4>
                        <p>{this.state.reservation_user_mobile}</p>
                      </div>
                      <br></br>
                      <div className="reservation_user">
                        <h4 className="text-uppercase">
                          {language.numberofpax}
                        </h4>
                        <p>
                          {this.state.number_of_adult} {language.adults} &{" "}
                          {this.state.number_of_child} {language.child}
                        </p>
                      </div>
                      <br></br>
                      <div className="reservation_Date">
                        <h4 className="text-uppercase">{language.date}</h4>
                        <p>{this.state.reservation_date}</p>
                      </div>
                      <br></br>
                      <div className="reservation_Time">
                        <h4 className="text-uppercase">{language.time}</h4>
                        <p>
                          {show_reservation_time}
                          {/* { moment( this.state.reservation_time, 'HH:mm:ss').format( "hh:mm A")} - { moment( this.state.reservation_end_time, 'HH:mm:ss').format( "hh:mm A")} */}
                        </p>
                      </div>
                      <br></br>
                      <div className="reservation_notes">
                        <h4 className="text-uppercase">
                          {language.specialinstruction}
                        </h4>
                        <p>{this.state.reservation_notes}</p>
                      </div>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tnk-chk-order">
                <Link className="button" to={"/myreservations"}>
                  {language.checkbookingstatus}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Reservationthankyou;
