/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import cookie from "react-cookies";
import { language } from "../Helpers/Config";
class Failed extends Component {
  constructor(props) {
    super(props);
    var order_status = "1";
    var urlParams = new URLSearchParams(this.props.location.search);
    if (
      urlParams.get("payment_status") == "failure" &&
      urlParams.get("referenceId") != "" &&
      urlParams.get("referenceId") == cookie.load("ocbcreferenceID")
    ) {
      var order_status = urlParams.get("order_status");
    } else {
      window.location.href = "/";
    }
    this.state = {
      order_status: order_status,
      ocbcreferenceID: cookie.load("ocbcreferenceID"),
    };
    setTimeout(
      function () {
        window.location.href = "/";
      }.bind(this),
      15000
    );
    cookie.remove("ocbcreferenceID", { path: "/" });
  }
  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>{language.order}</p>
          </div>
        </div>

        <div className="aboutus-page failed-page">
          <div className="container-one cms-content">
            <div className="common-inner-banner">
              {(this.state.order_status === "1" ||
                this.state.order_status === "0") && (
                <p>
                  {language.orderfailed}
                  <br></br>
                  {language.refno}: {this.state.ocbcreferenceID}{" "}
                </p>
              )}
              {this.state.order_status == "9" && (
                <p>{language.ordercancelled}</p>
              )}
            </div>
          </div>
        </div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

export default Failed;
